<template>
  <div class="row no-wrap items-center">
    <h1 class="text-h4">Need a help?</h1>
  </div>

  <div>We are here to support you ASAP</div>
  <div>To get help, please contact us via a below email</div>

  <div class="q-mt-md">
    <div class="bg-light-green-1">info@sango-tech.com</div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class Help extends Vue {}
</script>
